import { useState, useEffect, useCallback } from 'react'
import { numberFormat } from 'utils/app.utils'
import TableRowCommon from 'component/Table/TableRowCommon'
import TableCustom from 'component/Table/TableCustom'
import _ from 'lodash'
import * as UseStyled from 'features/report/useStyled'
import { Box, tabClasses } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { dateToView } from 'utils/date.utils'
import { UseSnCode } from 'utils/useSnCode'
import { log } from 'console'
interface FcComponent {
  page: number
  pageLimit: number
  rowCount: number
  sortBy: string
  sortType: string
  data: any
  dataExport?: any
  onRequestSort: (sortBy: string, sortType: string) => void
  handleChangeRowsPerPage: (val: any) => void
  handleChangePage: (val: any) => void
}

export default function IncomeList(props: FcComponent) {
  const { t } = useTranslation()
  const [snType, setSnType] = useState(false)

  const handleSnCode = useCallback(() => {
    const fetchData = async () => {
      const sn = await UseSnCode()
      setSnType(sn)
    }

    fetchData()
  }, [snType])

  useEffect(() => {
    handleSnCode()
  }, [])

  const headCells = [
    { id: 'message', disablePadding: false, labelKey: 'วันที่', align: 'center', class: 'text-nowrap', rowSpan: 2 },
    { id: 'price', disablePadding: false, labelKey: 'เงินสด', align: 'right', class: 'text-nowrap', rowSpan: 2 },
    { id: 'price', disablePadding: false, labelKey: 'เงินโอน', align: 'right', class: 'text-nowrap', rowSpan: 2 },
    { id: 'price', disablePadding: false, labelKey: 'เดบิต', align: 'right', class: 'text-nowrap', rowSpan: 2 },
    { id: 'price', disablePadding: false, labelKey: 'เครดิต', align: 'right', class: 'text-nowrap', rowSpan: 2 },
    { id: 'price', disablePadding: false, labelKey: 'สิทธิการรักษา', align: 'right', class: 'text-nowrap', rowSpan: 2 },
    { id: 'price', disablePadding: false, labelKey: 'ชำระล่วงหน้า', align: 'center', class: 'text-nowrap', rowSpan: 2 },
    { id: 'price', disablePadding: false, labelKey: 'Gift Voucher', align: 'center', class: 'text-nowrap', rowSpan: 2 },
    { id: 'price', disablePadding: false, labelKey: 'ส่วนลดพนักงาน', align: 'right', class: 'text-nowrap', rowSpan: 2 },
    { id: 'price', disablePadding: false, labelKey: 'หักเงินคืน', align: 'right', class: 'text-nowrap', rowSpan: 2 },
    { id: 'price', disablePadding: false, labelKey: 'รวมต่อวัน', align: 'right', class: 'text-nowrap', rowSpan: 2 },

    { id: 'message', disablePadding: false, labelKey: 'รากฟันเทียม', align: 'center', class: 'text-wrap border', rowSpan: 1, colSpan: 5, },
    { id: 'price', disablePadding: false, labelKey: 'จำนวนกระดูก', align: 'right', class: 'text-nowrap border-left', row: 2 },
    { id: 'price', disablePadding: false, labelKey: 'จำนวนแมมเบรน', align: 'right', class: 'text-nowrap ', row: 2 },
    { id: 'price', disablePadding: false, labelKey: 'จำนวนราก', align: 'right', class: 'text-nowrap ', row: 2 },
    { id: 'price', disablePadding: false, labelKey: 'ยอด IMP', align: 'right', class: 'text-nowrap ', row: 2 },
    { id: 'price', disablePadding: false, labelKey: 'ยอดรวม', align: 'right', class: 'text-nowrap border-right', row: 2 },

    { id: 'message', disablePadding: false, labelKey: 'ใส่ฟัน', align: 'center', class: 'text-nowrap border', rowSpan: 1, colSpan: 2 },
    { id: 'price', disablePadding: false, labelKey: 'จำนวน', align: 'right', class: 'text-nowrap  border-left', row: 2 },
    { id: 'price', disablePadding: false, labelKey: 'ยอดรวม', align: 'right', class: 'text-nowrap border-right', row: 2 },

    { id: 'message', disablePadding: false, labelKey: 'จัดฟันใส', align: 'center', class: 'text-nowrap border', rowSpan: 1, colSpan: 2 },
    { id: 'price', disablePadding: false, labelKey: 'จำนวน', align: 'right', class: 'text-nowrap  border-left', row: 2 },
    { id: 'price', disablePadding: false, labelKey: 'ยอดรวม', align: 'right', class: 'text-nowrap border-right', row: 2 },

    { id: 'message', disablePadding: false, labelKey: 'จัดฟัน', align: 'center', class: 'text-nowrap border', rowSpan: 1, colSpan: 2 },
    { id: 'price', disablePadding: false, labelKey: 'จำนวน', align: 'right', class: 'text-nowrap  border-left', row: 2 },
    { id: 'price', disablePadding: false, labelKey: 'ยอดรวม', align: 'right', class: 'text-nowrap border-right', row: 2 },

    { id: 'message', disablePadding: false, labelKey: 'รีเทนเนอร์', align: 'center', class: 'text-nowrap border', rowSpan: 1, colSpan: 2 },
    { id: 'price', disablePadding: false, labelKey: 'จำนวน', align: 'right', class: 'text-nowrap  border-left', row: 2 },
    { id: 'price', disablePadding: false, labelKey: 'ยอดรวม', align: 'right', class: 'text-nowrap border-right', row: 2 },

    { id: 'message', disablePadding: false, labelKey: 'ฟอกสีฟัน', align: 'center', class: 'text-nowrap border', rowSpan: 1, colSpan: 2 },
    { id: 'price', disablePadding: false, labelKey: 'จำนวน', align: 'right', class: 'text-nowrap  border-left', row: 2 },
    { id: 'price', disablePadding: false, labelKey: 'ยอดรวม', align: 'right', class: 'text-nowrap border-right', row: 2 },

    { id: 'message', disablePadding: false, labelKey: 'วีเนียร์', align: 'center', class: 'text-nowrap border', rowSpan: 1, colSpan: 2 },
    { id: 'price', disablePadding: false, labelKey: 'จำนวน', align: 'right', class: 'text-nowrap  border-left', row: 2 },
    { id: 'price', disablePadding: false, labelKey: 'ยอดรวม', align: 'right', class: 'text-nowrap border-right', row: 2 },

    { id: 'message', disablePadding: false, labelKey: 'เอ็กซ์เรย์', align: 'center', class: 'text-nowrap border', rowSpan: 1, colSpan: 2 },
    { id: 'price', disablePadding: false, labelKey: 'จำนวน', align: 'right', class: 'text-nowrap  border-left', row: 2 },
    { id: 'price', disablePadding: false, labelKey: 'ยอดรวม', align: 'right', class: 'text-nowrap border-right', row: 2 },

    { id: 'message', disablePadding: false, labelKey: 'ทั่วไปอื่นๆ', align: 'center', class: 'text-nowrap border', rowSpan: 1, colSpan: 2 },
    { id: 'price', disablePadding: false, labelKey: 'จำนวน', align: 'right', class: 'text-nowrap  border-left', row: 2 },
    { id: 'price', disablePadding: false, labelKey: 'ยอดรวม', align: 'right', class: 'text-nowrap border-right', row: 2 },

    { id: 'message', disablePadding: false, labelKey: 'ผลิตภัณฑ์(PD)', align: 'center', class: 'text-nowrap border', rowSpan: 1, colSpan: 2 },
    { id: 'price', disablePadding: false, labelKey: 'จำนวน', align: 'right', class: 'text-nowrap  border-left', row: 2 },
    { id: 'price', disablePadding: false, labelKey: 'ยอดรวม', align: 'right', class: 'text-nowrap border-right', row: 2 },

    { id: 'price', disablePadding: false, labelKey: 'รวม', align: 'right', class: 'text-nowrap', rowSpan: 2 },
    { id: 'price', disablePadding: false, labelKey: 'มูลค่างาน', align: 'right', class: 'text-nowrap', rowSpan: 2 },

    { id: 'message', disablePadding: false, labelKey: 'Gift Voucher', align: 'center', class: 'text-nowrap border', rowSpan: 1, colSpan: 2 },
    { id: 'price', disablePadding: false, labelKey: 'เอ็กซ์เรย์', align: 'right', class: 'text-nowrap  border-left', row: 2 },
    { id: 'price', disablePadding: false, labelKey: 'ทันตกรรมทั่วไป', align: 'right', class: 'text-nowrap border-right', row: 2 },

    { id: 'price', disablePadding: false, labelKey: 'ส่วนลดพนักงาน', align: 'right', class: 'text-nowrap', rowSpan: 2 },
    { id: 'message', disablePadding: false, labelKey: 'หมายเหตุ', align: 'left', class: 'text-nowrap', rowSpan: 2 }
  ]

  const renderData = (data: any, no: number) => {
    const num = props.page * props.pageLimit - props.pageLimit + no + 1

    const paidOther = Number(data.paidCaseTx) - (Number(data.paidCaseDentalImplant) + Number(data.paidCase2) + Number(data.paidCase3) + Number(data.paidCase4) + Number(data.paidCaseInvisalign) + Number(data.paidCaseOrthodontics) + Number(data.paidCaseRetainer) + Number(data.paidCaseTeethWhitening) + Number(data.paidCaseVeneer) + Number(data.paidCaseXray))
    const caseOther = Number(data.caseAll) - (Number(data.caseDentalImplant) + Number(data.case2) + Number(data.case3) + Number(data.case4) + Number(data.caseInvisalign) + Number(data.caseOrthodontics) + Number(data.caseRetainer) + Number(data.caseTeethWhitening) + Number(data.caseVeneer) + Number(data.caseXray))

    const columns = [
      { option: 'TEXT', align: 'center', label: dateToView(data.paidDate), class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: numberFormat(data.cashPaid), class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: <div dangerouslySetInnerHTML={{ __html: data.transferPaidText || '-' }} />, class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: numberFormat(data.debitPaid) || '-', class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: numberFormat(data.creditPaid) || '-', class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: <div dangerouslySetInnerHTML={{ __html: data.treamentRightPaidText || '-' }} />, class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: numberFormat(data.advancePaid) || '-', class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: numberFormat(data.voucherPaid) || '-', class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: numberFormat(0) || '-', class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: numberFormat(0) || '-', class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: numberFormat(data.paidCaseTx) || '-', class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: data.case1 || '-', class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: data.case2 || '-', class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: data.case3 || '-', class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: numberFormat(data.paidCase1 + data.paidCase2 + data.paidCase3) || '-', class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: numberFormat(data.paidCaseDentalImplant), class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: data.case4 || '-', class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: numberFormat(data.paidCase4), class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: data.caseInvisalign || '-', class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: numberFormat(data.paidCaseInvisalign), class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: data.caseOrthodontics || '-', class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: numberFormat(data.paidCaseOrthodontics), class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: data.caseRetainer || '-', class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: numberFormat(data.paidCaseRetainer), class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: data.caseTeethWhitening || '-', class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: numberFormat(data.paidCaseTeethWhitening), class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: data.caseVeneer || '-', class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: numberFormat(data.paidCaseVeneer), class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: data.caseXray || '-', class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: numberFormat(data.paidCaseXray), class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: caseOther < 0 ? 0 : caseOther || '-', class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: numberFormat(paidOther < 0 ? 0 : paidOther), class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: data.casePD || '-', class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: numberFormat(data.paidCasePD), class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: numberFormat(Number(data.paidCaseTx || 0) + Number(data.paidCasePD || 0)), class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: numberFormat(Number(data.voucherPaid)), class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: numberFormat(Number(0)), class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: numberFormat(Number(0)), class: 'text-nowrap' },
      { option: 'TEXT', align: 'right', label: numberFormat(Number(0)), class: 'text-nowrap' },
      { option: 'TEXT', align: 'left', label: '-', class: 'text-nowrap' },

    ]


    return <TableRowCommon key={num.toString()} id={no.toString()} obj={data} columns={columns} />
  }

  const renderDataSum = () => {
    const data = props.data
    const paidOther = _.sumBy(data, (o: any) => Number(o.paidCaseTx)) - (_.sumBy(data, (o: any) => Number(o.paidCaseDentalImplant)) + _.sumBy(data, (o: any) => Number(o.paidCase2)) + _.sumBy(data, (o: any) => Number(o.paidCase3)) + _.sumBy(data, (o: any) => Number(o.paidCase4)) + _.sumBy(data, (o: any) => Number(o.paidCaseInvisalign)) + _.sumBy(data, (o: any) => Number(o.paidCaseOrthodontics)) + _.sumBy(data, (o: any) => Number(o.paidCaseRetainer)) + _.sumBy(data, (o: any) => Number(o.paidCaseTeethWhitening)) + _.sumBy(data, (o: any) => Number(o.paidCaseVeneer)) + _.sumBy(data, (o: any) => Number(o.paidCaseXray)))
    const caseOther = _.sumBy(data, (o: any) => Number(o.caseAll)) - (_.sumBy(data, (o: any) => Number(o.caseDentalImplant)) + _.sumBy(data, (o: any) => Number(o.case2)) + _.sumBy(data, (o: any) => Number(o.case3)) + _.sumBy(data, (o: any) => Number(o.case4)) + _.sumBy(data, (o: any) => Number(o.caseInvisalign)) + _.sumBy(data, (o: any) => Number(o.caseOrthodontics)) + _.sumBy(data, (o: any) => Number(o.caseRetainer)) + _.sumBy(data, (o: any) => Number(o.caseTeethWhitening)) + _.sumBy(data, (o: any) => Number(o.caseVeneer)) + _.sumBy(data, (o: any) => Number(o.caseXray)))


    return <>
      <TableRowCommon
        key={`sum`}
        id={`sum`}
        obj={null}
        columns={[
          { option: 'TEXT', align: 'left', label: "รวม" || "-" },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, (o: any) => Number(o.cashPaid))), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, (o: any) => Number(o.transferPaid))), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, (o: any) => Number(o.debitPaid))), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, (o: any) => Number(o.creditPaid))), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, (o: any) => Number(o.treamentRightPaid))), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, (o: any) => Number(o.advancePaid))), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, (o: any) => Number(o.voucherPaid))), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, (o: any) => Number(o.paidCaseTx))), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: _.sumBy(data, (o: any) => Number(o.case1)), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: _.sumBy(data, (o: any) => Number(o.case2)), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: _.sumBy(data, (o: any) => Number(o.case3)), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, (o: any) => Number(o.paidCase1)) + _.sumBy(data, (o: any) => Number(o.paidCase2)) + _.sumBy(data, (o: any) => Number(o.paidCase3))), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, (o: any) => Number(o.paidCaseDentalImplant))), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: _.sumBy(data, (o: any) => Number(o.case4)), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, (o: any) => Number(o.paidCase4))), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: _.sumBy(data, (o: any) => Number(o.caseInvisalign)), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, (o: any) => Number(o.paidCaseInvisalign))), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: _.sumBy(data, (o: any) => Number(o.caseOrthodontics)), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, (o: any) => Number(o.paidCaseOrthodontics))), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: _.sumBy(data, (o: any) => Number(o.caseRetainer)), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, (o: any) => Number(o.paidCaseRetainer))), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: _.sumBy(data, (o: any) => Number(o.caseTeethWhitening)), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, (o: any) => Number(o.paidCaseTeethWhitening))), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: _.sumBy(data, (o: any) => Number(o.caseVeneer)) || ' - ', class: 'text- nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, (o: any) => Number(o.paidCaseVeneer))), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: _.sumBy(data, (o: any) => Number(o.caseXray)), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, (o: any) => Number(o.paidCaseXray))), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: caseOther < 0 ? 0 : caseOther, class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(paidOther < 0 ? 0 : paidOther), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: _.sumBy(data, (o: any) => Number(o.casePD)), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, (o: any) => Number(o.paidCasePD))), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, (o: any) => Number(o.paidCaseTx) || 0) + Number(_.sumBy(data, (o: any) => Number(o.paidCasePD)) || 0)), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(_.sumBy(data, (o: any) => Number(o.voucherPaid))), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(Number(0)), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(Number(0)), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', label: numberFormat(Number(0)), class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', label: '-', class: 'text-nowrap' },
        ]}
      />
    </>
  }

  return (
    <Box>
      <UseStyled.ResponsiveReportTable>
        <TableCustom
          rowHeadCell={2}
          page={props.page}
          pageLimit={props.pageLimit}
          sortType={props.sortType}
          sortBy={props.sortBy}
          rowCount={props.rowCount}
          onSort={props.onRequestSort}
          setPageLimit={props.handleChangeRowsPerPage}
          setPage={props.handleChangePage}
          headCells={headCells}
          rowsData={[..._.map(props.data, renderData), renderDataSum()]}
          customScroll
        />
      </UseStyled.ResponsiveReportTable>
    </Box>
  )
}
export function SummaryPayment(props: any) {
  const headCells = [
    { id: 'message', disablePadding: false, labelKey: 'ประเภทการจ่าย', align: 'left', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'REPORT.TABLE.INCOME_DAY.SUMMARY_INCOME', align: 'right', class: 'text-nowrap' },
  ]

  const renderData = () => {
    const data = props.data
    const renderHtml: any = []
    // เงินสด โอน บัรเครดิต บัตรเดบิต สิท
    let sumPaid = 0
    const sumCashPaid: any = {}
    const sumTransferPaid: any = {}
    const sumCreditPaid: any = {}
    const sumDebitPaid: any = {}
    const sumTreamentRightPaid: any = {}
    const sumAdvancePaid: any = {}
    const sumGiftVoucher: any = {}
    const sumPromotion: any = {}


    for (let index = 0; index < props.data.length; index++) {
      const d = props.data[index];

      sumPaid += d.totalPaid
      if (d.cashPaid > 0) {
        if (!sumCashPaid['เงินสด']) sumCashPaid['เงินสด'] = 0
        sumCashPaid['เงินสด'] += d.cashPaid
      }
      if (d.advancePaid > 0) {
        if (!sumAdvancePaid['เงินล่วงหน้า']) sumAdvancePaid['เงินล่วงหน้า'] = 0
        sumAdvancePaid['เงินล่วงหน้า'] += d.advancePaid
      }
      if (d.voucherPaid > 0) {
        if (!sumGiftVoucher['Gift Voucher']) sumGiftVoucher['Gift Voucher'] = 0
        sumGiftVoucher['Gift Voucher'] += d.voucherPaid
      }
      if (d.promotionPaid > 0) {
        if (!sumPromotion['Promotion']) sumPromotion['Promotion'] = 0
        sumPromotion['Promotion'] += d.promotionPaid
      }

      if (d.transferPaid > 0) {
        const transfer = d.transferPaidTextSpit.split(", <br/>")
        if (transfer.length) {
          for (const p of transfer) {
            const c = p.split(" | ")
            if (!sumTransferPaid[c[0].trim()]) sumTransferPaid[c[0].trim()] = 0
            sumTransferPaid[c[0].trim()] += Number(c[1].trim())
          }
        }
      }
      if (d.creditPaid > 0) {
        const credit = d.creditPaidTextSpit.split(", <br/>")
        if (credit.length) {
          for (const p of credit) {
            const c = p.split(" | ")
            if (!sumCreditPaid[c[0].trim()]) sumCreditPaid[c[0].trim()] = 0
            sumCreditPaid[c[0].trim()] += Number(c[1].trim())
          }
        }
      }
      if (d.debitPaid > 0) {
        const debit = d.debitPaidTextSpit.split(", <br/>")
        if (debit.length) {
          for (const p of debit) {
            const c = p.split(" | ")
            if (!sumDebitPaid[c[0].trim()]) sumDebitPaid[c[0].trim()] = 0
            sumDebitPaid[c[0].trim()] += Number(c[1].trim())
          }
        }
      }
      if (d.treamentRightPaid > 0) {
        const tr = d.treamentRightPaidTextSpit.split(", <br/>")
        if (tr.length) {
          for (const p of tr) {
            const c = p.split(" | ")
            if (!sumTreamentRightPaid[c[0].trim()]) sumTreamentRightPaid[c[0].trim()] = 0
            sumTreamentRightPaid[c[0].trim()] += Number(c[1].trim())
          }
        }
      }
    }
    const sumChanel: any = _.merge(sumCashPaid, sumTransferPaid, sumCreditPaid, sumDebitPaid, sumTreamentRightPaid, sumGiftVoucher, sumPromotion)
    _.map(sumChanel, (d, i) => {
      renderHtml.push(
        <>
          <TableRowCommon
            key={`key`}
            id={`key`}
            obj={data}
            columns={[
              { option: 'TEXT', align: 'left', label: i || "-" },
              { option: 'TEXT', align: 'right', label: numberFormat(d) || "-" }
            ]}
          />
        </>
      )

    })

    renderHtml.push(
      <>
        <TableRowCommon
          key={`key`}
          id={`key`}
          obj={data}
          columns={[
            { option: 'TEXT', align: 'right', label: "รวม" },
            { option: 'TEXT', align: 'right', label: numberFormat(sumPaid) }
          ]}
        />
      </>
    )
    return renderHtml
  }

  return (
    <>
      <Box sx={{ maxWidth: '480px' }}>
        <UseStyled.ResponsiveReportTable>
          <TableCustom
            page={props.page}
            pageLimit={props.pageLimit}
            sortType={props.sortType}
            sortBy={props.sortBy}
            rowCount={props.rowCount}
            onSort={props.onRequestSort}
            setPageLimit={props.handleChangeRowsPerPage}
            setPage={props.handleChangePage}
            headCells={headCells}
            rowsData={[...renderData()]}
            hidePagination={true}
            tableMinWidth={480}
            customScroll
          />
        </UseStyled.ResponsiveReportTable>
      </Box>
    </>
  )
}
export function SummaryList(props: any) {
  const headCells = [
    { id: 'message', disablePadding: false, labelKey: 'รายการ', align: 'left', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'ยอดรวม', align: 'right', class: 'text-nowrap' },
  ]

  const renderData = () => {
    const data = props.data
    const sumPaid = _.sumBy(data, function (d: any) { return d.totalPaid; });
    const sumVouchecr = _.sumBy(data, function (d: any) { return d.voucherPaid; });
    const sumPaidNet = _.sumBy(data, function (d: any) { return d.totalPaid - d.voucherPaid; });

    return [
      <TableRowCommon
        key={``}
        id={``}
        obj={data}
        columns={[
          { option: 'TEXT', align: 'left', label: "ยอดรวมค่ารักษา" },
          { option: 'TEXT', align: 'right', label: numberFormat(sumPaid) || "-" }
        ]}
      />,
      <TableRowCommon
        key={``}
        id={``}
        obj={data}
        columns={[
          { option: 'TEXT', align: 'left', label: "Gift Voucher" },
          { option: 'TEXT', align: 'right', label: numberFormat(sumVouchecr) || "-" }
        ]}
      />,
      <TableRowCommon
        key={``}
        id={``}
        obj={data}
        columns={[
          { option: 'TEXT', align: 'left', label: "ยอดรวมค่ารักษา (หลังหัก Gift Voucher)" },
          { option: 'TEXT', align: 'right', label: numberFormat(sumPaidNet) || "-" }
        ]}
      />,
    ]
  }

  return (
    <>
      <Box sx={{ maxWidth: '480px' }}>
        <UseStyled.ResponsiveReportTable>
          <TableCustom
            page={props.page}
            pageLimit={props.pageLimit}
            sortType={props.sortType}
            sortBy={props.sortBy}
            rowCount={props.rowCount}
            onSort={props.onRequestSort}
            setPageLimit={props.handleChangeRowsPerPage}
            setPage={props.handleChangePage}
            headCells={headCells}
            rowsData={[...renderData()]}
            hidePagination={true}
            tableMinWidth={480}
            customScroll
          />
        </UseStyled.ResponsiveReportTable>
      </Box>
    </>
  )
}
export function SummaryTx(props: any) {


  const headCells = [
    { id: 'message', disablePadding: false, labelKey: 'ประเภทหัตถการ', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'จำนวน', align: 'right', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'ยอดรวม', align: 'right', class: 'text-nowrap' },
  ]
  const renderData = () => {
    const data = props.data
    const _data: any = {}
    const renderHtml: any = []
    const paidOther = _.sumBy(data, (o: any) => Number(o.paidCaseTx)) - (
      _.sumBy(data, (o: any) =>
        Number(o.paidCaseDentalImplant) +
        Number(o.paidCase1) +
        Number(o.paidCase2) +
        Number(o.paidCase3) +
        Number(o.paidCase4) +
        Number(o.paidCaseInvisalign) +
        Number(o.paidCaseOrthodontics) +
        Number(o.paidCaseRetainer) +
        Number(o.paidCaseTeethWhitening) +
        Number(o.paidCaseVeneer) +
        Number(o.paidCaseXray)
      )
    )


    const caseOther = _.sumBy(data, (o: any) => Number(o.caseAll)) - (
      _.sumBy(data, (o: any) =>
        Number(o.caseDentalImplant) +
        Number(o.case1) +
        Number(o.case2) +
        Number(o.case3) +
        Number(o.case4) +
        Number(o.caseInvisalign) +
        Number(o.caseOrthodontics) +
        Number(o.caseRetainer) +
        Number(o.caseTeethWhitening) +
        Number(o.caseVeneer) +
        Number(o.caseXray)
      )
    )


    _data['รากฟันเทียม'] = [numberFormat(_.sumBy(data, (o: any) => Number(o.caseDentalImplant))), numberFormat(_.sumBy(data, (o: any) => Number(o.paidCaseDentalImplant)))]
    _data['รากฟันเทียม (ยอด IMP)'] = [_.sumBy(data, (o: any) => Number(o.case1) + Number(o.case2) + Number(o.case3)), numberFormat(_.sumBy(data, (o: any) => Number(o.paidCase1) + Number(o.paidCase2) + Number(o.paidCase3)))]
    _data['รากฟันเทียม (จำนวนกระดูก)'] = [_.sumBy(data, (o: any) => Number(o.case1)), numberFormat(_.sumBy(data, (o: any) => Number(o.paidCase1)))]
    _data['รากฟันเทียม (จำนวนแมมเบรน)'] = [_.sumBy(data, (o: any) => Number(o.case2)), numberFormat(_.sumBy(data, (o: any) => Number(o.paidCase2)))]
    _data['รากฟันเทียม (จำนวนราก)'] = [_.sumBy(data, (o: any) => Number(o.case3)), numberFormat(_.sumBy(data, (o: any) => Number(o.paidCase3)))]
    _data['ใส่ฟัน'] = [_.sumBy(data, (o: any) => Number(o.case4)), numberFormat(_.sumBy(data, (o: any) => Number(o.paidCase4)))]
    _data['จัดฟันใส'] = [_.sumBy(data, (o: any) => Number(o.caseInvisalign)), numberFormat(_.sumBy(data, (o: any) => Number(o.paidCaseInvisalign)))]
    _data['จัดฟัน'] = [_.sumBy(data, (o: any) => Number(o.caseOrthodontics)), numberFormat(_.sumBy(data, (o: any) => Number(o.paidCaseOrthodontics)))]
    _data['รีเทนเนอร์'] = [_.sumBy(data, (o: any) => Number(o.caseRetainer)), numberFormat(_.sumBy(data, (o: any) => Number(o.paidCaseRetainer)))]
    _data['ฟอกสีฟัน'] = [_.sumBy(data, (o: any) => Number(o.caseTeethWhitening)), numberFormat(_.sumBy(data, (o: any) => Number(o.paidCaseTeethWhitening)))]
    _data['วีเนียร์'] = [_.sumBy(data, (o: any) => Number(o.caseVeneer)), numberFormat(_.sumBy(data, (o: any) => Number(o.paidCaseVeneer)))]
    _data['เอ็กซ์เรย์'] = [_.sumBy(data, (o: any) => Number(o.caseXray)), numberFormat(_.sumBy(data, (o: any) => Number(o.paidCaseXray)))]
    _data['ทั่วไปอื่นๆ'] = [caseOther < 0 ? 0 : caseOther, numberFormat(paidOther < 0 ? 0 : paidOther)]

    const totalPaidNet = _.sumBy(data, (o: any) => Number(o.totalPaid))
    const totalQty = _.sumBy(data, (o: any) => Number(o.caseAll))
    _.map(_data, (d, i) => {

      const text = d[0]
      renderHtml.push(
        <TableRowCommon
          key={i.toString()}
          id={i.toString()}
          obj={d}
          columns={[
            { option: 'TEXT', align: 'left', label: i },
            { option: 'TEXT', align: 'center', label: text },
            { option: 'TEXT', align: 'right', label: d[1] || "-" }
          ]}
        />
      )
    })

    renderHtml.push(
      <TableRowCommon
        key={`sum`}
        id={`sum`}
        obj={[]}
        columns={[
          { option: 'TEXT', align: 'right', label: 'รวม' },
          { option: 'TEXT', align: 'center', label: totalQty },
          { option: 'TEXT', align: 'right', label: numberFormat(totalPaidNet) || "-" }
        ]}
      />
    )

    return renderHtml
  }

  return (
    <>
      <Box sx={{ maxWidth: '480px' }}>
        <UseStyled.ResponsiveReportTable>
          <TableCustom
            page={props.page}
            pageLimit={props.pageLimit}
            sortType={props.sortType}
            sortBy={props.sortBy}
            rowCount={props.rowCount}
            onSort={props.onRequestSort}
            setPageLimit={props.handleChangeRowsPerPage}
            setPage={props.handleChangePage}
            headCells={headCells}
            rowsData={[...renderData()]}
            hidePagination={true}
            tableMinWidth={480}
            customScroll
          />
        </UseStyled.ResponsiveReportTable>
      </Box>
    </>
  )
}
export function SummaryPD(props: any) {
  const headCells = [
    { id: 'message', disablePadding: false, labelKey: 'ประเภทผลิตภัณฑ์', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'รายการผลิตภัณฑ์', align: 'left', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'จำนวน', align: 'left', class: 'text-nowrap' },
    { id: 'price', disablePadding: false, labelKey: 'ยอดที่จ่าย', align: 'right', class: 'text-nowrap' },
  ]


  const renderData = () => {
    const data = props.data
    const renderHtml: any = []

    let totalPaidNet = 0
    let totalQty = 0
    _.map(data, (d, i) => {
      totalPaidNet += d.totalPaid
      totalQty += d.casePD
      renderHtml.push(
        <TableRowCommon
          key={i.toString()}
          id={i.toString()}
          obj={d}
          columns={[
            { option: 'TEXT', align: 'left', label: d.productTypeName || "-" },
            { option: 'TEXT', align: 'left', label: d.productName || "-" },
            { option: 'TEXT', align: 'right', label: d.casePD },
            { option: 'TEXT', align: 'right', label: numberFormat(d.totalPaid) || "-" }
          ]}
        />
      )
    })

    renderHtml.push(
      <TableRowCommon
        key={`sum`}
        id={`sum`}
        obj={[]}
        columns={[
          { option: 'MERGE', align: 'right', colSpan: 1, label: '' },
          { option: 'TEXT', align: 'right', label: 'รวม' },
          { option: 'TEXT', align: 'right', label: totalQty },
          { option: 'TEXT', align: 'right', label: numberFormat(totalPaidNet) || "-" }
        ]}
      />
    )

    return renderHtml
  }

  return (
    <>
      <Box sx={{ maxWidth: '480px' }}>
        <UseStyled.ResponsiveReportTable>
          <TableCustom
            page={props.page}
            pageLimit={props.pageLimit}
            sortType={props.sortType}
            sortBy={props.sortBy}
            rowCount={props.rowCount}
            onSort={props.onRequestSort}
            setPageLimit={props.handleChangeRowsPerPage}
            setPage={props.handleChangePage}
            headCells={headCells}
            rowsData={[...renderData()]}
            hidePagination={true}
            tableMinWidth={480}
            customScroll
          />
        </UseStyled.ResponsiveReportTable>
      </Box>
    </>
  )
}